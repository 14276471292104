import { graphql } from "gatsby"
import React from "react"
import HeaderWithOptionalButtons from "./HeaderWithOptionalButtons"

export const fragment = graphql`
  fragment HeaderWithOptionalButtonsFragment on WpPage_Flexlayouts_FlexibleLayouts_HeaderWithOptionalButtons {
    sectionHeading
    description
    buttonsRepeater {
      button {
        title
        url
        target
      }
    }
    image {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            height: 500
            quality: 75
            placeholder: NONE
          )
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFHeaderWithOptionalButtons = ({
  breadcrumbData,
  sectionHeading,
  description,
  buttonsRepeater: buttons,
  image,
  config,
}) => {
  return (
    <HeaderWithOptionalButtons
      breadcrumbData={breadcrumbData}
      sectionHeading={sectionHeading}
      buttons={buttons}
      image={image}
      description={description}
      config={config}
    />
  )
}
// add background image query
