// extracted by mini-css-extract-plugin
export var bgGrey100 = "HeaderWithOptionalButtons-module--bg-grey-100--361cd";
export var bgGrey150 = "HeaderWithOptionalButtons-module--bg-grey-150--c430a";
export var bgGrey200 = "HeaderWithOptionalButtons-module--bg-grey-200--fa651";
export var bgGrey300 = "HeaderWithOptionalButtons-module--bg-grey-300--30dcf";
export var bgGrey400 = "HeaderWithOptionalButtons-module--bg-grey-400--ff53b";
export var bgGrey500 = "HeaderWithOptionalButtons-module--bg-grey-500--4ebfe";
export var bgGrey600 = "HeaderWithOptionalButtons-module--bg-grey-600--e3c50";
export var bgGrey700 = "HeaderWithOptionalButtons-module--bg-grey-700--7b1ca";
export var bgGrey800 = "HeaderWithOptionalButtons-module--bg-grey-800--dd2a1";
export var bgGrey900 = "HeaderWithOptionalButtons-module--bg-grey-900--b22c8";
export var contentCls = "HeaderWithOptionalButtons-module--contentCls--53306";
export var headerCls = "HeaderWithOptionalButtons-module--headerCls--9f7d3";
export var imgWrapperCls = "HeaderWithOptionalButtons-module--imgWrapperCls--9f31e";
export var textBkgdCls = "HeaderWithOptionalButtons-module--textBkgdCls--536ba";
export var textGrey100 = "HeaderWithOptionalButtons-module--text-grey-100--9454a";
export var textGrey150 = "HeaderWithOptionalButtons-module--text-grey-150--f8149";
export var textGrey200 = "HeaderWithOptionalButtons-module--text-grey-200--1b8fb";
export var textGrey300 = "HeaderWithOptionalButtons-module--text-grey-300--33962";
export var textGrey400 = "HeaderWithOptionalButtons-module--text-grey-400--c8c0d";
export var textGrey500 = "HeaderWithOptionalButtons-module--text-grey-500--56316";
export var textGrey600 = "HeaderWithOptionalButtons-module--text-grey-600--583e7";
export var textGrey700 = "HeaderWithOptionalButtons-module--text-grey-700--3b034";
export var textGrey800 = "HeaderWithOptionalButtons-module--text-grey-800--9328f";
export var textGrey900 = "HeaderWithOptionalButtons-module--text-grey-900--ee3c3";