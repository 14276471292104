import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Breadcrumb from "../../UI/Breadcrumb/Breadcrumb"
import { Header } from "../../UI/Common"
import { LinkButton } from "../../UI/Common"

import {
  headerCls,
  contentCls,
  textBkgdCls,
  imgWrapperCls,
} from "./HeaderWithOptionalButtons.module.scss"

const HeaderWithOptionalButtons = ({
  breadcrumbData,
  sectionHeading,
  description,
  image,
  buttons,
  config,
}) => {
  const { padding } = config || {}

  return (
    <Header className={headerCls} padding={padding}>

      <div className={contentCls}>
        <div className={textBkgdCls} />
        <Container>
          <Row>
            <Col xs={12} lg={6} className="my-3 my-lg-0">
            {breadcrumbData && (
              <Breadcrumb data={breadcrumbData} className="pl-1" />
            )}
              <h1 className=" my-md-3">{sectionHeading}</h1>
              <div className="my-4">{description}</div>
              {buttons &&
                React.Children.toArray(buttons.map(item => {
                  const { title, url } = item.button
                  return (
                    <LinkButton className="my-2 mr-md-2 my-lg-3" link={url}>
                      {title}
                    </LinkButton>
                  )
                }))}
            </Col>
            {image && (
              <Col xs={12} lg={6} className="my-3 my-lg-0">
                <div className="position-relative">
                  <div className={imgWrapperCls}>
                    <GatsbyImage
                      loading="eager"
                      className="h-100"
                      alt={image.altText || ""}
                      objectFit="contain"
                      image={getImage(image.localFile)}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Header>
  )
}

export default HeaderWithOptionalButtons
